<template>
  <div>
    <video-carousel-skeleton v-if="isLoading"/>
    <div class="space-y-2 section-wrap" v-else>
      <h1 class="text-2xl font-medium section-title highlight-txt">
        <span>{{ title }} </span>
        <div v-if="moreLink" class="title-more-wrap">
          <router-link :to="moreLink" class="title-more-button">Бүгд</router-link>
          <router-link :to="moreLink" class="title-more-button-arrow">›</router-link>
        </div>
      </h1>
      <video-carousel :title="title" :row="row" :height="height" :play="play" :num="num" :budges="budges"
                      :force-play="forcePlay"
                      :items="$store.state[getter]" :ep-number="epNumber"/>
    </div>
  </div>
</template>

<script>
import VideoCarouselSkeleton from "../skeletons/VideoCarouselSkeleton.vue";
import VideoCarousel from "./VideoCarousel.vue";

export default {
  props: ["title", "action", "actionProps", "getter", "height", "row", "play", "num", "budges", "moreLink", "epNumber", "forcePlay"],
  components: {
    VideoCarousel,
    VideoCarouselSkeleton,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  created() {
    this.$store.dispatch(this.action, {vm: this, props: this.actionProps})
  },
  methods: {
    getLink() {
      console.log(this.$store.state[this.getter]);
    }
  }
};
</script>

<style>
</style>

<!--<template>-->
<!--  <div>-->
<!--    <video-carousel-skeleton v-if="isLoading"/>-->
<!--    <div class="space-y-2 section-wrap" v-else>-->
<!--      <h1 class="text-2xl font-medium section-title highlight-txt">-->
<!--        <span>{{ title }} </span>-->
<!--        <div v-if="moreLink" class="title-more-wrap">-->
<!--          <router-link :to="moreLink" class="title-more-button">Бүгд</router-link>-->
<!--          <router-link :to="moreLink" class="title-more-button-arrow">›</router-link>-->
<!--        </div>-->

<!--        <div class="carousel-actions">-->
<!--        </div>-->
<!--      </h1>-->

<!--      <div class="carousel-wrapper media-carousel">-->
<!--        <a href="javascript:void(0)" v-if="$store.state[getter].length > 5" class="prev-btn" @click="prev"><i-->
<!--            class="isax isax-arrow-left-2"></i></a>-->
<!--        <Carousel ref="carousel" :itemsToShow="2" :loop="true" :breakpoints="breakpoints" :wrapAround="false"-->
<!--                  :transition="500">-->
<!--          <Slide v-for="item in $store.state[getter]" :key="item.anime_id">-->
<!--            <div class="media-item">-->
<!--              <router-link v-if="epNumber" :to="`/watch/${item.anime_id}/${item.id}`" class="media-cover">-->
<!--                <a href="javascript:void(0)" class="card__play">-->
<!--                  <i class="isax isax-play"></i>-->
<!--                </a>-->
<!--                <p class="media-cover-title">{{ item.atitle }}</p>-->
<!--              </router-link>-->

<!--              <router-link v-else :to="`/theatre/detail/${item.anime_id}`" class="media-cover">-->
<!--                <a href="javascript:void(0)" class="card__play">-->
<!--                  <i class="isax isax-play"></i>-->
<!--                </a>-->
<!--                <p class="media-cover-title">{{ item.title }}</p>-->
<!--              </router-link>-->

<!--              <div v-if="epNumber" class="ep-number">-->
<!--                {{ item.number }}-р анги-->
<!--              </div>-->

<!--              <span v-if="item.is_free" class="card__rate card__rate&#45;&#45;green price">Үнэгүй</span>-->

<!--              <img :src="Poster" v-lazy:background-image="imageUrl(item.image1, true)"-->
<!--                   class="poster rounded-md"/>-->
<!--              <div class="time-bar-wrap">-->
<!--                <div class="time-bar" :style="`width: ${item.percent ? item.percent : 0}%`"></div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </Slide>-->

<!--&lt;!&ndash;          <Slide v-if="showAdditionalElement" :key="'additional-item'">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="media-item">&ndash;&gt;-->
<!--&lt;!&ndash;              <router-link :to="moreLink" class="cat-more">&ndash;&gt;-->
<!--&lt;!&ndash;                {{ title }} <br> бүгдийг үзэх&ndash;&gt;-->
<!--&lt;!&ndash;              </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;              <img :src="Poster" class="poster rounded-md cat-more-back"/>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </Slide>&ndash;&gt;-->
<!--          <template #addons>-->
<!--            <pagination/>-->
<!--          </template>-->
<!--        </Carousel>-->
<!--        <a href="javascript:void(0)" v-if="$store.state[getter].length > 5" class="next-btn" @click="next"><i-->
<!--            class="isax isax-arrow-right-3"></i></a>-->
<!--      </div>-->

<!--      &lt;!&ndash;      <video-carousel :title="title" :row="row" :height="height" :play="play" :num="num" :budges="budges"&ndash;&gt;-->
<!--      &lt;!&ndash;                      :items="$store.state[getter]" :ep-number="epNumber"/>&ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--import VideoCarouselSkeleton from "../skeletons/VideoCarouselSkeleton.vue";-->
<!--import Poster from "../assets/poster-hz.png";-->
<!--// import VideoCarousel from "./VideoCarousel.vue";-->
<!--import {imageUrl} from "../utils";-->
<!--import {Carousel, Slide, Pagination} from 'vue3-carousel'-->

<!--export default {-->
<!--  props: ["title", "action", "getter", "height", "row", "play", "num", "budges", "moreLink", "epNumber"],-->
<!--  components: {-->
<!--    // VideoCarousel,-->
<!--    VideoCarouselSkeleton,-->
<!--    Carousel,-->
<!--    Slide,-->
<!--    Pagination-->
<!--  },-->
<!--  data() {-->
<!--    return {-->
<!--      isLoading: false,-->
<!--      Poster,-->
<!--      breakpoints: {-->
<!--        // 700px and up-->
<!--        700: {-->
<!--          itemsToShow: 2,-->
<!--          itemsToScroll: 2,-->
<!--          snapAlign: 'center',-->
<!--        },-->

<!--        980: {-->
<!--          itemsToShow: 3,-->
<!--          itemsToScroll: 3,-->
<!--          snapAlign: 'center',-->
<!--        },-->

<!--        1024: {-->
<!--          itemsToShow: 4,-->
<!--          itemsToScroll: 4,-->
<!--          snapAlign: 'center',-->
<!--        },-->

<!--        // 1024 and up-->
<!--        1360: {-->
<!--          itemsToShow: 5,-->
<!--          itemsToScroll: 1,-->
<!--          snapAlign: 'start',-->
<!--        },-->

<!--        // 1024 and up-->
<!--        1920: {-->
<!--          itemsToShow: 5,-->
<!--          itemsToScroll: 1,-->
<!--          snapAlign: 'start',-->
<!--        },-->
<!--      },-->
<!--    }-->
<!--  },-->
<!--  created() {-->
<!--    this.$store.dispatch(this.action, {vm: this})-->
<!--  },-->
<!--  computed: {-->
<!--    showAdditionalElement() {-->
<!--      return this.$store.state[this.getter].length > 0;-->
<!--    },-->
<!--  },-->
<!--  methods: {-->
<!--    imageUrl,-->
<!--    getLink() {-->
<!--      console.log(this.$store.state[this.getter]);-->
<!--    },-->
<!--    next() {-->
<!--      this.$refs.carousel.next()-->
<!--    },-->
<!--    prev() {-->
<!--      this.$refs.carousel.prev()-->
<!--    },-->
<!--  }-->
<!--};-->
<!--</script>-->

<!--<style>-->
<!--</style>-->
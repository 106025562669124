<template>
  <div
      ref="container"
      class="cursor-pointer video-card relative pt-[56.25%]"
      :class="{
      'animate-card-hover': isScaled,
      'animate-card-unhover': !isScaled,
    }"
      :style="'padding-top: ' + height + '%'"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
  >
    <div @click="handleClick(data.anime_id, data.epId | data.id)" class="static card_container">
      <div v-if="isHoverVideo && isScaled && hoverVideoPath != null" class="hover-video">
        <video-background :src="hoverVideoPath"/>
      </div>
      <Image
          v-else
          :src="data.image_hz"
          :size="185"
          class="static thumb-img"
          :class="{ 'shadow': isScaled }"
          :alt="data.title"
      />

<!--      <div class="hover-play">-->
<!--        <a href="javascript:void(0)" class="card__play"><i class="isax isax-play"></i></a>-->
<!--      </div>-->

      <div class="time-bar-wrap">
        <div class="time-bar" :style="`width: ${data.percent ? data.percent : 0}%`"></div>
      </div>

      <div v-show="num && !isScaled" :class="ishover ? 'ep-number' : 'no-show'">
        {{ data.number }}-р анги
      </div>

      <div v-if="epNumber && !isScaled" class="ep-number">
        {{ data.number }}-р анги
      </div>

      <div v-show="budges" class="absolute top-1 right-2">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full opacity-25"
              :class="budges == 'Шинэ' ? 'bg-[#c11010]' : 'bg-[#59698a]'"></span>
        <button class="text-2xs rounded px-3 py-1 bg-opacity-70"
                :class="budges == 'Шинэ' ? 'bg-[#c11010]' : 'bg-[#59698a]'">
          {{ budges }}
        </button>
      </div>
    </div>

    <div v-if="isMouseEnter && isDesktop"
         class="absolute top-full w-full h-26 bg-background rounded-b-md transition-all duration-300 shadow p-3 space-y-2"
         :class="[!isScaled ? 'invisible opacity-0' : 'visible opacity-100']">
<!--      <div class="flex items-center justify-between">-->
<!--        <div class="flex items-center space-x-2">-->
<!--&lt;!&ndash;          <circle-button v-tooltip.top="'Үзэх'"&ndash;&gt;-->
<!--&lt;!&ndash;                         v-if="!data.is_mirror && !data.is_blacklist"&ndash;&gt;-->
<!--&lt;!&ndash;                         @click="handleClickShow(data.anime_id, data.epId | data.id)"&ndash;&gt;-->
<!--&lt;!&ndash;                         class="bg-white text-black border-white hover:bg-gray-300 action-btn">&ndash;&gt;-->
<!--&lt;!&ndash;            <IconPlayFill class="text-xs"/>&ndash;&gt;-->
<!--&lt;!&ndash;          </circle-button>&ndash;&gt;-->

<!--          <div v-if="data.liked == 1 || data.liked == '1'" v-tooltip.top="'Таалагдсан хэсгээс хасах'">-->
<!--            <circle-button class="p-1 action-btn-active" @click="setMediaStatus('like', 0)">-->
<!--              <Heart class="text-lg"/>-->
<!--            </circle-button>-->
<!--          </div>-->

<!--          <div v-else v-tooltip.top="'Таалагдлаа'">-->
<!--            <circle-button class="p-1" @click="setMediaStatus('like', 1)">-->
<!--              <Heart class="text-lg text-red"/>-->
<!--            </circle-button>-->
<!--          </div>-->
<!--        </div>-->

<!--        <circle-button class="p-1" @click="handleClick(data.anime_id || data.id)" v-tooltip.top="'Дэлгэрэнгүй'">-->
<!--          <IconKeyboardArrowDown class="text-icon"/>-->
<!--        </circle-button>-->
<!--      </div>-->

      <p class="line-clamp-2 title-txt highlight-txt">{{ data.atitle || data.title }}</p>

      <div class="line-clamp-6 text-justify txt-desc-scale" v-html="data.description"></div>

      <div class="flex items-center space-x-2 justify-end card-bottom-info">
        <div v-if="data.rate != null" class="flex items-center text-yellow-500 card-rate">
          <IconStar/>
          <p>{{ data.rate }}/10</p>
        </div>

        <div v-if="data.age_limit != null" class="flex items-center card-age highlight-txt">
          <IconPlus/>
          <p>{{ data.age_limit }}</p>
        </div>
        <p>
          {{ data.year }}
        </p>

        <a class="hover-more-btn" @click="handleClick(data.anime_id || data.id)" href="javascript:void(0)">
          <span>Дэлгэрэнгүй</span>
          <i class="isax isax-arrow-right-3"></i>
        </a>

<!--        <circle-button class="p-1" @click="handleClick(data.anime_id || data.id)" v-tooltip.top="'Дэлгэрэнгүй'">-->
<!--          <IconKeyboardArrowDown class="text-icon"/>-->
<!--        </circle-button>-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {ref} from "vue";
// import IconPlayFill from "~icons/ph/play-fill";
// import IconPlus from "~icons/ic/outline-plus";
import IconStar from "~icons/ic/sharp-star-purple500";
// import IconKeyboardArrowDown from "~icons/ic/outline-keyboard-arrow-down";
// import Heart from "~icons/fluent/heart-20-regular";
import useDevice from "../hooks/useDevice";
import Image from "./Image.vue";
// import CircleButton from "./CircleButton.vue";
import config from "../store/_boot/config"

export default {
  props: ["data", "title", "height", "play", "num", "ishover", "budges", "epNumber"],
  components: {
    Image,
    // IconPlayFill,
    // Heart,
    // CircleButton,
    // IconKeyboardArrowDown,
    // IconPlus,
    IconStar,
  },

  setup(props) {
    const isScaled = ref(false);
    const isHoverVideo = ref(false);
    const hoverVideoPath = ref(null);
    const timeout = ref(null);
    const container = ref(null);
    const isMouseEnter = ref(false);
    const mouseLeaveTimeout = ref(null);
    const {isDesktop} = useDevice();

    const getHoverVideo = (animeID) => {
      axios.get(`${config.HOST}/api/m/anime/hover/${animeID}`).then(({data}) => {
        if (data.status) {
          isHoverVideo.value = true;
          hoverVideoPath.value = data.p;
        }
      })
    }

    const getCutVideo = () => {
      if (props.data.short_video != null) {
        axios.post(`${config.HOST}/api/m/ep/hover/v`, {
          p: props.data.short_video
        }).then(({data}) => {
          if (data.status) {
            isHoverVideo.value = true;
            hoverVideoPath.value = data.p;
          }
        })
      }
    }

    const handleMouseEnter = () => {
      if (timeout.value) {
        clearTimeout(timeout.value);
      }

      if (mouseLeaveTimeout.value) {
        clearTimeout(mouseLeaveTimeout.value);
      }

      isMouseEnter.value = true;
      timeout.value = setTimeout(() => {
        isScaled.value = true;
        if (props.epNumber || props.num) {
          getCutVideo(props.data.anime_id);
        } else {
          getHoverVideo(props.data.anime_id);
        }
      }, 500);
    };

    const handleMouseLeave = () => {
      if (timeout.value) {
        clearTimeout(timeout.value);
      }

      isScaled.value = false;
      isHoverVideo.value = false;
      hoverVideoPath.value = null;
      this.$refs.container.classList.add('animate-card-unhover')

      mouseLeaveTimeout.value = setTimeout(() => {
        isMouseEnter.value = false;
      }, 500);
    };

    return {
      isDesktop,
      isScaled,
      isHoverVideo,
      hoverVideoPath,
      isMouseEnter,
      container,
      handleMouseEnter,
      handleMouseLeave
    };
  },

  methods: {
    handleClick(animeID) {
      this.$router.push('/theatre/detail/' + animeID);
      // setModalActive(true);
    },

    handleClickShow(animeID, epID) {
      console.log(animeID, epID);
      this.$router.push(`/watch/${animeID}/${epID}`)
    },

    setMediaStatus(action, value) {
      this.$store.dispatch('setStatus', {vm: this, action, value})
    }
  }
};
</script>

<style scoped>
.shadow {
  -webkit-box-shadow: 0px 0px 12px 0px #000000;
  box-shadow: 0px 0px 12px 0px #000000;
}

.title-txt {
  font-size: 12px !important;
}

.desc-txt {
  font-size: 9px !important;
}

.video-card img {
  @apply object-cover absolute top-0 left-0 w-full h-full;
}
</style>
<template>
  <div class="section-wrap space-y-2">
    <h1 class="text-2xl font-medium">
      <h1 class="text-2xl font-medium section-title highlight-txt">
        <span>{{ title }} </span>
        <div v-if="moreLink" class="title-more-wrap">
          <router-link :to="moreLink" class="title-more-button">Бүгд</router-link>
          <router-link :to="moreLink" class="title-more-button-arrow">›</router-link>
        </div>
      </h1>
    </h1>
    <video-carousel :items="items"/>
  </div>
</template>

<script>
import VideoCarousel from "./VideoCarousel.vue";

export default {
  props: ["title", "items", "moreLink"],
  components: {
    VideoCarousel
  }
};
</script>

<style>
</style>

<!--<template>-->
<!--  <div class="section-wrap space-y-2">-->
<!--    <h1 class="text-2xl font-medium">-->
<!--      <h1 class="text-2xl font-medium section-title highlight-txt">-->
<!--        <span>{{ title }} </span>-->
<!--        <div v-if="moreLink" class="title-more-wrap">-->
<!--          <router-link :to="moreLink" class="title-more-button">Бүгд</router-link>-->
<!--          <router-link :to="moreLink" class="title-more-button-arrow">›</router-link>-->
<!--        </div>-->
<!--      </h1>-->
<!--    </h1>-->
<!--    <div class="carousel-wrapper media-carousel">-->
<!--      <a href="javascript:void(0)" v-if="items.length > 5" class="prev-btn" @click="prev"><i-->
<!--          class="isax isax-arrow-left-2"></i></a>-->
<!--      <Carousel ref="carousel" :itemsToShow="2" :loop="true" :breakpoints="breakpoints" :wrapAround="false"-->
<!--                :transition="500">-->
<!--        <Slide v-for="item in items" :key="item.anime_id">-->
<!--          <div class="media-item effect-sadie">-->
<!--            <router-link :to="`/theatre/detail/${item.anime_id}`" class="media-cover">-->
<!--              <a href="javascript:void(0)" class="card__play">-->
<!--                <i class="isax isax-play"></i>-->
<!--              </a>-->
<!--              <p class="media-cover-title">{{ item.title }}</p>-->
<!--            </router-link>-->

<!--&lt;!&ndash;            <span class="card__rate card__rate&#45;&#45;green">{{ item.rate ? item.rate : '0.0' }}</span>&ndash;&gt;-->
<!--            <img :src="Poster" v-lazy:background-image="imageUrl(item.image_hz, true)"-->
<!--                 class="poster rounded-md"/>-->
<!--          </div>-->
<!--        </Slide>-->
<!--&lt;!&ndash;        <Slide v-if="showAdditionalElement" :key="'additional-item'">&ndash;&gt;-->

<!--&lt;!&ndash;          <div class="media-item">&ndash;&gt;-->
<!--&lt;!&ndash;            <router-link :to="moreLink" class="cat-more">&ndash;&gt;-->
<!--&lt;!&ndash;              {{ title }} <br> бүгдийг үзэх&ndash;&gt;-->
<!--&lt;!&ndash;            </router-link>&ndash;&gt;-->
<!--&lt;!&ndash;            <img :src="Poster" class="poster rounded-md cat-more-back"/>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </Slide>&ndash;&gt;-->

<!--        <template #addons>-->
<!--          <pagination/>-->
<!--        </template>-->
<!--      </Carousel>-->

<!--      <a href="javascript:void(0)" v-if="items.length > 5" class="next-btn" @click="next"><i-->
<!--          class="isax isax-arrow-right-3"></i></a>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--import {Carousel, Slide, Pagination} from 'vue3-carousel'-->
<!--import {imageUrl} from "../utils";-->
<!--import Poster from "../assets/poster-hz.png";-->

<!--export default {-->
<!--  props: ["title", "items", "moreLink"],-->
<!--  components: {-->
<!--    Carousel, Slide, Pagination-->
<!--  },-->
<!--  data() {-->
<!--    return {-->
<!--      Poster,-->
<!--      breakpoints: {-->
<!--        // 700px and up-->
<!--        700: {-->
<!--          itemsToShow: 2,-->
<!--          itemsToScroll: 2,-->
<!--          snapAlign: 'center',-->
<!--        },-->

<!--        980: {-->
<!--          itemsToShow: 3,-->
<!--          itemsToScroll: 3,-->
<!--          snapAlign: 'center',-->
<!--        },-->

<!--        1024: {-->
<!--          itemsToShow: 4,-->
<!--          itemsToScroll: 4,-->
<!--          snapAlign: 'center',-->
<!--        },-->

<!--        // 1024 and up-->
<!--        1360: {-->
<!--          itemsToShow: 5,-->
<!--          itemsToScroll: 5,-->
<!--          snapAlign: 'start',-->
<!--        },-->

<!--        // 1024 and up-->
<!--        1920: {-->
<!--          itemsToShow: 5,-->
<!--          itemsToScroll: 6,-->
<!--          snapAlign: 'start',-->
<!--        },-->
<!--      }-->
<!--    }-->
<!--  },-->
<!--  computed: {-->
<!--    showAdditionalElement() {-->
<!--      return this.items.length > 0;-->
<!--    },-->
<!--  },-->
<!--  methods: {-->
<!--    imageUrl,-->
<!--    next() {-->
<!--      this.$refs.carousel.next()-->
<!--    },-->
<!--    prev() {-->
<!--      this.$refs.carousel.prev()-->
<!--    },-->
<!--  }-->
<!--};-->
<!--</script>-->

<!--<style>-->
<!--</style>-->